.f60-700 {
    font-size: 60px;
    font-weight: 700;
}

.f40-700 {
    font-size: 40px;
    font-weight: 700;
}

.f32-700 {
    font-size: 32px;
    font-weight: 700;
}

.f24-700 {
    font-size: 24px;
    font-weight: 700;
}
.f24-600 {
    font-size: 24px;
    font-weight: 600;
}

.f24-500 {
    font-size: 24px;
    font-weight: 500;
}

.f20-500 {
    font-size: 20px;
    font-weight: 500;
}

.f18-700 {
    font-size: 18px;
    font-weight: 700;
}

.f18-400 {
    font-size: 18px;
    font-weight: 400;
}

.f16-700 {
    font-size: 16px;
    font-weight: 700;
}

.f16-500 {
    font-size: 16px;
    font-weight: 500;
}

.f16-400 {
    font-size: 16px;
    font-weight: 400;
}

.f14-700 {
    font-size: 14px;
    font-weight: 700;
}

.f14-500 {
    font-size: 14px;
    font-weight: 500;
}

.f12-600 {
    font-size: 12px;
    font-weight: 600;
}

.f12-400 {
    font-size: 12px;
    font-weight: 400;
}
