.container {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.header {
    text-align: center;
    padding: 30px;
}

.input_top_container {
    padding: 0 10px;
    border: none;
}

.input_top {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    border: none;
    max-width: 887px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}

.show_btn {
    background-color: #151515 !important;
    color: #2DC5CB !important;
    padding: 10px !important;
}

.search_icon {
    margin-right: 12px;
}

.light_blue_container {
    background-color: var(--light-blue);
    display: flex;
    /*align-items: center;*/
    gap: 0 73px;
    justify-content: center;
    padding: 20px;
}

.avatar {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar > img {
    width: 100%;
    height: 100%
}

.category_container {
    display: flex;
    justify-content: center;
    padding: 69px 0 114px 0;
}

.category_container > div {
    max-width: 1110px;
    width: 100%;
}

.footer {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 120px 0;
    background-color: var(--light-blue);
}

.footer_input {
    display: grid;
    grid-template-columns: 1fr auto;
    border: none !important;
    padding: 0 0 0 10px !important;
}

.footer_input_container {
    width: 583px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    margin-top: 50px;
    background-color: #fff;
}

.footer_submit {
    background-color: #816AFE !important;
    height: 100% !important;
    padding: 22px 30px !important;
    color: #fff !important;
}
