.container {
}

.info {
    background-color: var(--light-blue);
    text-align: center;
    padding: 40px 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    justify-content: center;
    align-items: center;
    gap: 20px 200px;
    margin-top: 50px;
}

.column {
    display: flex;
    gap: 0 10px;
}

.row {
    display: flex;
    gap: 0 65px;
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    margin-top: 40px;
}
