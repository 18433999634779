.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0 8px 24px 0 #15151526;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.block {
    display: inline-flex;
    flex-direction: column;
    margin-top: 140px;
}

.btn {
    justify-content: flex-start;
    overflow: hidden;
    background-color: white;
    padding: 10px 0;
    gap: 0;
}

.btn > div:first-child {
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top {
    position: relative;
    display: flex;
}

.logo {
    position: absolute;
    overflow: hidden;
    width: 100%;
    margin-left: 10px;
}

.open_close_btn {
    position: absolute;
    transform: translate(50%, 50%);
     width: 32px;
    height: 32px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

}

.title {
    transition: all 200ms linear;
    width: auto;
    max-width: 200px;
}

.show_title {
    width: 0;
    max-width: 0;
}
