.container {
    display: inline-flex;
    align-items: center;
    user-select: none;
    background: none;
    border: none;
    gap: 12px;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    width: auto;
    white-space: nowrap;
    box-sizing: border-box;
    color: var(--color);
    position: relative;
    z-index: 1;
}

.container[data-disabled="true"] {
    background-color: #cccccc;
    border: 1px solid #cccccc;
    color: black;
}

.container_primary {
    background-color: var(--primary-blue);
    color: white;
    border: 1px solid var(--primary-blue);
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
}

.container_outlined {
    border: 1px solid #151515;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 700 ;
}

.container_link {
    font-size: 18px;
    font-weight: 400;
}

.container_primary,
.container_outlined,
.container_secondary {
    justify-content: center;
    transition: color 160ms linear;
    height: 45px;
}

.container_primary:hover,
.container_outlined:hover,
.container_secondary:hover {
    opacity: .9;
}
