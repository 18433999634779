.trending_container {
    background-color: var(--light-blue);
    padding: 47px 0 150px 0;
}

.trending_block {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3, 460px);
    gap: 25px;
    justify-content: center;
}

.trending_block_card {
    box-shadow: 0 8px 24px 0 rgba(21, 21, 21, 0.15);
    background-color: white;
    display: flex;
    gap: 0 20px;
    padding: 20px 0;
}

.trending_block_card_btn {
    margin-top: 18px;
}

.trending_block_card img{
    width: 200px;

}
.trending_block_card_btn:hover {
    background-color: #000;
    color: #2DC5CB;
}

.just_launched_container {
    background-color: #fff;
    padding: 47px 0 150px 0;
}

.row{
    display: flex;
    align-items: center;
    gap: 0 20px;
}

.row > div{
    display: flex;
    align-items: center;
    gap: 4px;
}
