.container {
    width: auto;
    display: block;
}

.container svg {
    pointer-events: none;
}

.container :is(input, textarea) {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    /*color: #FFFFFFB2;*/
    font-family: "Open Sans", serif;
    align-items: center;
}

.container :is(input) {
    height: 66px;
}

textarea {
    padding-top: 10px;
}

.container input:-webkit-autofill,
.container input:-webkit-autofill:hover,
.container input:-webkit-autofill:focus,
.container textarea:-webkit-autofill,
.container textarea:-webkit-autofill:hover,
.container textarea:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    font: normal 400 18px / normal "Open Sans", serif;
}

.container:hover {
    /*color: rgba(255, 255, 255, 0.5);*/
}

.legend {
    color: rgb(204, 204, 204);
    font-size: 12px;
    font-weight: 400;
    padding: 0 6px;
}

.input {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 18px;
}

.input:focus-within .legend {
    color: rgba(255, 255, 255, 0.20);
}

.input:not(.error):focus-within {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.input_outlined {
    /*border: 1px solid rgba(0, 0, 0, 0.15);*/
    background-color: transparent;
    box-sizing: border-box;
}

.disabled {
    border: 1px solid rgba(0, 0, 0, 0.2);
    /*background-color: var(--input-bg-disabled);*/
    opacity: 0.9;
    user-select: none;
}

.label {
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
}

.error_text {
    color: var( --error-color);
    font-size: 12px;
    font-weight: normal;
    display: inline-flex;
    white-space: break-spaces;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
}

.require_star {
    color: var( --error-color);
    margin-left: 4px;
}

.error {
    border-color:var( --error-color);
    color: var( --error-color)
}

.alert_icon{
    margin-right: 8px;
}
