html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    color: var(--color);
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none !important;
}
* {
    box-sizing: border-box;
    font-family: "Open Sans", serif ;
}

a {
    text-decoration: none;
}

svg {
    pointer-events: none;
}

button {
    all: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    font: normal normal 18px / normal Roboto, serif;
    -webkit-text-fill-color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(200,200,200,.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(200,200,200,.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(200,200,200,.4);
}


.text_dots {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
